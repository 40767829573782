import React, { createContext, useContext, useState } from 'react';

// Create the context without TypeScript types
const JobsContext = createContext(undefined);

export const JobsProvider = ({ children }) => {
	const [jobs, setJobs] = useState([]);

	return (
		<JobsContext.Provider
			value={{
				jobs,
				setJobs,
			}}>
			{children}
		</JobsContext.Provider>
	);
};

export const useJobs = () => {
	const context = useContext(JobsContext);
	if (context === undefined) {
		throw new Error('useJobs must be used within a JobsProvider');
	}
	return context;
};
