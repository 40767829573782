import { useState, useEffect, createContext, useContext } from 'react';
import { getCookie, setCookie, deleteCookie } from '../utils/cookies';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

// Create context for app-wide state
const AppContext = createContext();

// Custom hook for app state including auth
const useAppState = () => {
	// Auth state
	const [authToken, setAuthToken] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [authCheckedOnLoad, setAuthCheckedOnLoad] = useState(false);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const navigate = useNavigate();

	const checkAuth = async () => {
		if (authCheckedOnLoad) {
			return;
		}
		setAuthCheckedOnLoad(true);

		const token = getCookie('token');
		if (!token) {
			setIsAuthenticated(false);
			setIsLoading(false);
			return;
		}
		if (isLoading) return;
		setAuthToken(token);

		try {
			setIsLoading(true);
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/auth/verifyToken`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			setUser(response.data);
			setIsAuthenticated(true);
		} catch (error) {
			deleteCookie('token');
			setIsAuthenticated(false);
		} finally {
			setIsLoading(false);
		}
	};

	const signIn = async (user) => {
		setCookie('token', user.token, 1);
		setAuthToken(user.token);
		setUser(user);
		setIsAuthenticated(true);
		//toast.info('Du är inloggad!');
		navigate('/');
	};

	const signOut = async () => {
		deleteCookie('token');
		setAuthToken(null);
		setUser(null);
		setIsAuthenticated(false);
		toast.info('Du är utloggad.');
		/* Redirect to login page */
		navigate('/signin');
	};

	const isAdmin = () => {
		if (!user) {
			return false;
		}
		return user.role === 'admin';
	};

	return {
		// Auth state
		isAuthenticated,
		isLoading,
		user,
		setUser,
		checkAuth,
		authToken,
		setAuthToken,
		signIn,
		signOut,
		isAdmin,
		authCheckedOnLoad,
		setAuthCheckedOnLoad,
		mobileMenuOpen,
		setMobileMenuOpen,
	};
};

// App provider component
const AppProvider = ({ children }) => {
	const appState = useAppState();

	useEffect(() => {}, []);

	return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};

// Custom hook to use app state in components
const useApp = () => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error('useApp must be used within an AppProvider');
	}
	return context;
};

export { AppProvider, useApp };
