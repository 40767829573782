import { useState } from 'react';

const useAlert = () => {
	const [alert, setAlert] = useState(null);

	const handleSetAlert = (alert) => {
		setAlert({
			message: alert.message ? alert.message : '',
			type: alert.type ? alert.type : 'info',
			timeout: alert.timeout ? alert.timeout : 3000,
		});
		// Optionally add a timeout to clear the alert automatically after a few seconds
		setTimeout(() => setAlert(null), alert.timeout ? alert.timeout : 3000); // Clear alert after 3 seconds
	};

	return { alert, setAlert, handleSetAlert };
};

export { useAlert };
