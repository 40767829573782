import { React, useEffect, useRef } from 'react';
import { Chat } from '../components/Chat/Chat';
import { useApp } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

export const ChatPage = () => {
	const { isAuthenticated } = useApp();
	const navigate = useNavigate();
	const hasRun = useRef(false);

	useEffect(() => {
		if (hasRun.current) {
			return;
		}
		hasRun.current = true;
		/* Redirect the user to the login page if they are not authenticated */
		if (!isAuthenticated) {
			navigate('/signin');
		}
	});

	return (
		<div>
			<Chat />
		</div>
	);
};
