import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

const ChatMessage = ({ message }) => {
	const renderMessage = (message) => {
		let content = '';
		if (message.parts && message.parts[0]) {
			content = message.parts[0].text;
		}
		if (message.content) {
			content = message.content;
		}
		return <Markdown>{content}</Markdown>;
	};

	return (
		<div className={`chat-message-wrapper ${message.role}`}>
			<div className={`chat-message ${message.role}`}>{renderMessage(message)}</div>
		</div>
	);
};

ChatMessage.propTypes = {
	message: PropTypes.object.isRequired,
};

export default ChatMessage;
