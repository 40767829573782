import { useState } from 'react';
import axios from 'axios';
import { Alert } from '../Alert';
import { useAlert } from '../../hooks/useAlert';
import { useApp } from '../../context/AppContext';

export const SignupForm = () => {
	const { alert, handleSetAlert } = useAlert();
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [invitationCode, setInvitationCode] = useState('');
	const { signIn } = useApp();

	const handleSubmit = async (event) => {
		if (isLoading) return;
		event.preventDefault();
		setIsLoading(true);
		const apiUrl = process.env.REACT_APP_API_URL;
		try {
			let data = {
				email: email,
				password: password,
				invitationCode: invitationCode,
			};
			const response = await axios.post(`${apiUrl}/auth/signup`, data);
			const user = response?.data?.extraData ? response?.data?.extraData : null;
			if (!user) {
				handleSetAlert({ message: 'Registreringen misslyckades', type: 'danger', timeout: 5000 });
				return;
			}
			handleSetAlert({ ...response.data, timeout: 3000 });
			window.setTimeout(() => {
				signIn(user);
			}, 3000);
		} catch (error) {
			handleSetAlert({ ...error.response.data, timeout: 5000 });
		} finally {
			setIsLoading(false);
		}
	};

	const handleSetEmail = (e) => {
		setEmail(e.target.value);
	};

	const handleSetPassword = (e) => {
		setPassword(e.target.value);
	};
	const handleSetInvitationCode = (e) => {
		setInvitationCode(e.target.value);
	};

	return (
		<div>
			<form className={`fb-form ${isLoading ? 'disabled' : ''}`} onSubmit={(e) => handleSubmit(e)}>
				<div className="row">
					<div className="col-12">
						<h1 className="mb-4">Skapa konto</h1>
					</div>
					<div className="col-12 col-md-6 mb-4">
						<label htmlFor="email">Email</label>
						<input
							className="form-control"
							id="email"
							name="email"
							type="email"
							placeholder="Ange din e-postadress"
							defaultValue={email}
							onChange={(e) => handleSetEmail(e)}></input>
					</div>
					<div className="col-12 col-md-6 mb-4">
						<label htmlFor="password">Lösenord</label>
						<input
							className="form-control"
							id="password"
							name="password"
							type="password"
							placeholder="Ange ditt lösenord"
							defaultValue={password}
							onChange={(e) => handleSetPassword(e)}></input>
					</div>
					<div className="col-12 col-md-8 mb-2">
						<label htmlFor="invitationCode">Inbjudningskod</label>
						<input
							className="form-control"
							id="invitationCode"
							name="invitationCode"
							type="text"
							required
							placeholder="Ange din inbjudningskod"
							defaultValue={invitationCode}
							onChange={(e) => handleSetInvitationCode(e)}></input>
					</div>
					<div className="col-12 col-md-4 mb-2 text-end">
						<label>&nbsp;</label>
						<button className="btn btn-primary w-100" type="submit">
							Skapa konto
						</button>
					</div>
					{alert && (
						<div className="col-12">
							<Alert className="mt-2 mb-2" message={alert.message} type={alert.type} timeout={alert.timeout} />
						</div>
					)}
				</div>
			</form>
		</div>
	);
};

SignupForm.propTypes = {};
