import React, { useEffect, useState } from 'react';
import { useChat } from '../../context/ChatContext';
import { Alert } from '../Alert';
import { useAlert } from '../../hooks/useAlert';
import { useApi } from '../../utils/api';
import { render } from '@testing-library/react';

const ThreadSelector = () => {
	const { alert, handleSetAlert } = useAlert();
	const api = useApi();

	const { availableThreads, setAvailableThreads, setActiveThread, activeThread, setChatMessages } = useChat();

	/* Set state for threadSelectorOpen */
	const [threadSelectorOpen, setThreadSelectorOpen] = useState(false);

	useEffect(() => {
		// Define an async function to fetch data
		const fetchData = async () => {
			try {
				const response = await api.get(`/chat/getAvailableThreads`);
				setAvailableThreads(response.data);
				setActiveThread(response.data[0]);
			} catch (error) {
				error && error.response && error.response.data && handleSetAlert({ ...error.response.data, timeout: 5000 });
			} finally {
			}
		};

		fetchData(); // Call the async function
	}, []); // Empty dependency array ensures this runs only once when the component mounts

	const renderAvailableThreads = () => {
		/* Get an array with all the forums */
		let threadsByForum = {};
		for (let thread of availableThreads) {
			if (!threadsByForum[thread.forum]) {
				threadsByForum[thread.forum] = [];
			}
			threadsByForum[thread.forum].push(thread);
		}

		/* Render the threads */
		return Object.keys(threadsByForum).map((forum, index) => {
			return (
				<div key={`forum-${forum}`} className="thread-selector-forum">
					<p className="thread-selector-forum-headline">{forum}</p>
					{threadsByForum[forum].map((thread, index) => {
						return (
							<a
								key={`thread-${thread.id}`}
								href="#"
								className="thread-selector-item"
								onClick={(event) => handleThreadChange(event, thread)}>
								{thread.title}
							</a>
						);
					})}
				</div>
			);
		});
	};

	const handleThreadChange = (event, thread) => {
		event.preventDefault();
		setThreadSelectorOpen(false);
		const selectedThreadId = thread.id;

		const selectedThread = availableThreads.find((thread) => {
			return thread.id == selectedThreadId;
		});
		setActiveThread(selectedThread);
		setChatMessages([]);
	};

	return (
		<div className="thread-selector mt-2">
			<div className="row align-items-center">
				<div className="col-12 col-md-auto ps-md-0">
					<p className="text-regular mb-1 mb-md-0">
						<strong>Välj tråd</strong>
					</p>
				</div>
				<div className="col-12 col-md pe-md-0">
					<div className="thread-selector-wrapper">
						<a
							href="#"
							className="thread-selector-main-item thread-selector-item"
							onClick={() => setThreadSelectorOpen(!threadSelectorOpen)}>
							{activeThread ? activeThread.title : 'Laddar trådar...'}
						</a>
						{threadSelectorOpen && <div className="thread-selector-dropdown">{renderAvailableThreads()}</div>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ThreadSelector;
