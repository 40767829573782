import React, { useEffect } from 'react';
import { Jobs } from '../components/Jobs/Jobs';
import { useApp } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const JobsPage = () => {
	const { user } = useApp();
	const navigate = useNavigate();

	useEffect(() => {
		/* Redirect the user to the login page if they are not authenticated */
		if (user) {
			if (user.role !== 'admin') {
				toast.error('Du har inte behörighet att visa den här sidan.');
				navigate('/signin');
			}
		}
	}, [user, navigate]);

	return (
		<div>
			<Jobs />
		</div>
	);
};
