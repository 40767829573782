import axios from 'axios';
import { getCookie } from './cookies';

const useApi = () => {
	const authToken = getCookie('token');
	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			'Content-Type': 'application/json',
		},
	});

	// Add a request interceptor to dynamically include the authToken
	axiosInstance.interceptors.request.use(
		(config) => {
			if (authToken) {
				config.headers.Authorization = `Bearer ${authToken}`;
			}
			return config;
		},
		(error) => Promise.reject(error)
	);

	return axiosInstance;
};

export { useApi };
