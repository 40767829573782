import { React, useEffect, useRef } from 'react';
import { useApp } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ProfilePage = () => {
	const { user, isAuthenticated } = useApp();
	const navigate = useNavigate();
	const hasRun = useRef(false);

	useEffect(() => {
		if (hasRun.current) {
			return;
		}
		hasRun.current = true;
		/* Redirect the user to the login page if they are not authenticated */
		if (!isAuthenticated || !user) {
			toast.error('Du måste vara inloggad för att hantera din profil.');
			navigate('/signin');
		}
	}, [isAuthenticated, user]);

	return (
		<div>
			<div className="container mt-5">
				<div className="row">
					<div className="col-12">
						<h1>Hej {user && user.email ? user.email : ''}!</h1>
					</div>
				</div>
			</div>
		</div>
	);
};
