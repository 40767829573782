import { useEffect } from 'react';
import { useJobs } from '../../context/JobsContext';
import { JobCard } from './JobCard';
import { useApi } from '../../utils/api';
import { CreateJobInput } from './CreateJobInput';
import { useNavigate } from 'react-router-dom';

export const Jobs = () => {
	const { jobs, setJobs } = useJobs();
	const api = useApi();
	const navigate = useNavigate();

	useEffect(() => {
		// This function runs only once when the component is mounted
		getAllJobs();

		/* Run getAllJobs every 10 seconds */
		const interval = setInterval(() => {
			getAllJobs();
		}, 10000);

		// Optional cleanup function, if needed
		return () => {
			// This runs when the component is unmounted
			window.clearInterval(interval);
		};
	}, []); // Empty dependency array ensures it only runs on mount

	const handleSetJobs = (newJobs) => {
		setJobs((oldJobs) => {
			const newJobs = [jobs];
			return newJobs;
		});
		setJobs(newJobs);
	};

	const getAllJobs = async () => {
		try {
			const response = await api.get(`/jobs/getAllJobs/`);
			let jobs = response.data;
			handleSetJobs(jobs);
		} catch (error) {
			if (error && error.response && error.response.status === 401) {
				navigate('/signin');
			}
		} finally {
		}
	};

	/*
	const renderJobs = () => {
		if (jobs.length) {
			return jobs.map((job, index) => {
				return (
					<tr key={`job-id-${job.id}`}>
						<td>
							<strong>{job.thread.title}</strong>
						</td>
						<td>{job.last_run_at ? formatDate(job.last_run_at) : '-'}</td>
						<td>
							<a href={job.url} target="_blank">
								{job.url}
							</a>
						</td>
						<td>{job.scraping_in_progress ? 'Ja' : 'Nej'}</td>
						<td>
							<a href={`${job.url}p${job.last_page_scraped}`} target="_blank">
								{job.last_page_scraped}
							</a>
						</td>
						<td>{job.thread ? job.thread.number_of_pages : ''}</td>
						<td>
							{job.is_active ? (
								<a
									href="#"
									onClick={(e) => {
										updateJob(e, job, { is_active: false });
									}}
									className="btn btn-small btn-outline-primary">
									Pausa
								</a>
							) : (
								<a
									href="#"
									onClick={(e) => {
										updateJob(e, job, { is_active: true });
									}}
									className="btn btn-small btn-outline-primary">
									Starta
								</a>
							)}
						</td>
					</tr>
				);
			});
		}
	};
    */

	const renderJobCards = () => {
		if (jobs.length) {
			return jobs.map((job, index) => {
				return <JobCard job={job} key={`job-id-${job.id}`} jobUpdated={getAllJobs} />;
			});
		}
	};

	return (
		<div>
			<div>
				<div className="mb-2">
					<div className="col-12 col-md-12 col-lg-8 offset-lg-2">
						<CreateJobInput getAllJobs={getAllJobs} />
					</div>
				</div>
			</div>
			<div className="">
				<div className="">
					<div className="">{renderJobCards()}</div>
				</div>
			</div>
		</div>
	);
};
