const formatDate = (dateString) => {
	const date = new Date(dateString);

	// Get the year, month, day, hour, and minute
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	const hour = date.getHours().toString().padStart(2, '0');
	const minute = date.getMinutes().toString().padStart(2, '0');

	// Construct the formatted string
	return `${year}-${month}-${day} ${hour}:${minute}`;
};
export { formatDate };
