import { React, useEffect } from 'react';
import { SignInForm } from '../components/Forms/SignInForm';
import { useApp } from '../context/AppContext';
//import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const SignInPage = () => {
	const { isAuthenticated } = useApp();
	const navigate = useNavigate();

	useEffect(() => {
		/* Redirect the user to the login page if they are not authenticated */
		if (isAuthenticated) {
			//toast.info('Du är inloggad!');
			navigate('/');
		}
	}, [isAuthenticated]);

	return (
		<div>
			<div className="container mt-5">
				<div className="row">
					<div className="col-12 col-md-12 col-lg-6 offset-lg-3">
						<SignInForm />
					</div>
				</div>
			</div>
		</div>
	);
};
