import React, { useEffect, useState } from 'react';
import { useChat } from '../../context/ChatContext';
import { Alert } from '../Alert';
import { useAlert } from '../../hooks/useAlert';
import { useApi } from '../../utils/api';

const Logs = () => {
	const { alert, handleSetAlert } = useAlert();
	const api = useApi();
	const [logs, setLogs] = useState(false);

	const {} = useChat();

	useEffect(() => {
		// Define an async function to fetch data
		const fetchData = async () => {
			try {
				const response = await api.get(`/admin/logs`);
				if (response.data.logs) {
					setLogs(response.data.logs);
				}
			} catch (error) {
				error && error.response && error.response.data && handleSetAlert({ ...error.response.data, timeout: 5000 });
			} finally {
			}
		};

		fetchData(); // Call the async function
	}, []); // Empty dependency array ensures this runs only once when the component mounts

	return (
		<div className="logs-wrapper">
			<pre>{logs}</pre>
		</div>
	);
};

export { Logs };
