import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import { ChatProvider } from './context/ChatContext';
import { JobsProvider } from './context/JobsContext';
import { Navbar } from './components/Navbar';
import { JobsPage } from './pages/JobsPage';
import { AdminPage } from './pages/AdminPage';
import { ChatPage } from './pages/ChatPage';
import { SignupPage } from './pages/SignupPage';
import { SignInPage } from './pages/SignInPage';
import { ProfilePage } from './pages/ProfilePage';
import { MainContent } from './components/MainContent';
import { ToastContainer, Slide } from 'react-toastify';

import './App.scss';

function App() {
	return (
		<div className="App">
			<Router>
				<AppProvider>
					<Navbar />
					<ChatProvider>
						<JobsProvider>
							<MainContent>
								<ToastContainer
									position="bottom-center"
									autoClose={5000}
									hideProgressBar
									newestOnTop
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme="light"
									transition={Slide}
								/>
								<Routes>
									<Route path="/signup" element={<SignupPage />} />
									<Route path="/signin" element={<SignInPage />} />
									<Route path="/profile" element={<ProfilePage />} />
									<Route path="/" element={<ChatPage />} />
									<Route path="/jobs" element={<JobsPage />} />
									<Route path="/admin" element={<AdminPage />} />
								</Routes>
							</MainContent>
						</JobsProvider>
					</ChatProvider>
				</AppProvider>
			</Router>
		</div>
	);
}

export default App;
