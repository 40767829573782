import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const Alert = ({ message, type, timeout, className }) => {
	const [isActive, setIsActive] = useState(true);

	useEffect(() => {
		/* Hide the alert after the timeout */
		setTimeout(() => {
			setIsActive(false);
		}, timeout);
	}, [timeout]); // Empty dependency array ensures it only runs on mount

	const renderAlert = () => {
		let typeClass = type;
		if (type === 'error') {
			typeClass = 'danger';
		}
		return (
			<div className={`${className} alert alert-${typeClass}`} role="alert">
				<div dangerouslySetInnerHTML={{ __html: message }} />
			</div>
		);
	};

	return <div>{isActive && renderAlert()}</div>;
};
Alert.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	timeout: PropTypes.number,
};
