import { useChat } from './../../context/ChatContext';
import { ReactComponent as SendIcon } from './../../assets/send.svg';
import { ReactComponent as TrashIcon } from './../../assets/trash.svg';
import { toast } from 'react-toastify';

export const ChatMessageInput = ({ handleSubmit }) => {
	const { userMessage, setUserMessage, setChatHistoryInLocalStorage, activeThread, setChatMessages } = useChat();

	const clearHistory = () => {
		setChatHistoryInLocalStorage([], activeThread.fb_id);
		setChatMessages([]);
		toast.info('Chatten har rensats');
	};

	return (
		<form onSubmit={(e) => handleSubmit(e)} className="chat-input-form">
			<div className="row mt-2 mb-2 align-items-end">
				<div className="col-auto">
					<a className={`btn btn-outline-danger w-100 btn-trash btn-svg`} onClick={clearHistory}>
						<TrashIcon />
					</a>
				</div>
				<div className="col">
					<div className="input-container mt-2">
						<input
							type="text"
							className="form-control"
							placeholder="Skriv meddelande"
							onChange={(e) => setUserMessage(e.target.value)}
							value={userMessage}
						/>
					</div>
				</div>
				<div className="col-auto">
					<div className="submit-container">
						<a className={`btn btn-primary w-100 btn-svg`} onClick={handleSubmit}>
							<span className="d-none d-sm-inline-block">Skicka</span>
							<span className="d-sm-none">
								<SendIcon />
							</span>
						</a>
					</div>
				</div>
			</div>
		</form>
	);
};
