import React from 'react';
import PropTypes from 'prop-types';

const ChatMessage = ({ message }) => {
	const showMessage = (message) => {
		let data = {
			__html: '',
		};
		if (message.parts && message.parts[0]) {
			data.__html = message.parts[0].text;
		}
		if (message.content) {
			data.__html = message.content;
		}

		/* Remove the Flashback style links with "leave.php" in them from the text to make it to make external links work */
		const regex = /href="([^"]*)"/g;
		const matches = data.__html.matchAll(regex);
		for (const match of matches) {
			// Extract and decode each URL
			const url = match[1];
			let decodedUrl = decodeURIComponent(url);
			decodedUrl = decodedUrl.replace(/\/leave\.php\?u=/g, '');
			data.__html = data.__html.replace(match[1], decodedUrl);
		}

		return data;
	};

	const showDate = (dateString) => {
		/* Sometimes the date is not passed from the API. In that case, return */
		if (!dateString) {
			return '';
		}
		const date = new Date(dateString);

		// Get the year, month, day, hour, and minute
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		const hour = date.getHours().toString().padStart(2, '0');
		const minute = date.getMinutes().toString().padStart(2, '0');

		// Construct the formatted string
		return `${year}-${month}-${day} ${hour}:${minute}`;
	};

	return (
		<div className={`chat-message-post-wrapper`}>
			<header className="chat-message-post-header">
				<p>{showDate(message.postDate)}</p>
				<a href={`https://www.flashback.org/${message.postLink}`} className="post-id-link" target="_blank">
					#{message.postId}
				</a>
			</header>
			<main className="chat-message-post-body">
				<div className="chat-message-post-user">
					<a href="#" className="username">
						{message.user}
					</a>
				</div>
				<div className="chat-message-post-content" dangerouslySetInnerHTML={showMessage(message)}></div>
			</main>
		</div>
	);
};

ChatMessage.propTypes = {
	message: PropTypes.object.isRequired,
};

export default ChatMessage;
