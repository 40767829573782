import React, { useEffect, useRef } from 'react';
import { useApp } from '../context/AppContext';

export const MainContent = ({ children }) => {
	const { checkAuth, authCheckedOnLoad, setAuthCheckedOnLoad } = useApp();

	const hasRun = useRef(false);

	useEffect(() => {
		if (!hasRun.current) {
			hasRun.current = true;
			checkAuth();
		}
	}, []);

	return <main className="main-content">{children}</main>;
};
