import { useState } from 'react';
import { Alert } from '../Alert';
import { useAlert } from '../../hooks/useAlert';
import PropTypes from 'prop-types';
import { formatDate } from '../../utils/formatDate';
import { useApi } from '../../utils/api';
import { toast } from 'react-toastify';

export const JobCard = ({ job, jobUpdated }) => {
	const { alert, handleSetAlert } = useAlert();
	const [isLoading, setIsLoading] = useState(false);
	const [showCardDetails, setShowCardDetails] = useState(false);
	const api = useApi();

	const updateJob = async (event, job, updatedParams) => {
		event.preventDefault();
		try {
			setIsLoading(true);
			const response = await api.post(`/jobs/updateJob/`, {
				...job,
				...updatedParams,
			});
			jobUpdated();
			toast.success('Det schemalagda jobbet har uppdaterats.');

			//handleSetAlert({ message: 'Det schemalagda jobbet har uppdaterats.', type: 'success' }, 3000);
		} catch (error) {
			console.log(error.response);
			handleSetAlert({ message: error.message, type: 'danger' });
		} finally {
			setIsLoading(false);
		}
	};

	const runSingleJob = async (event, job, updatedParams) => {
		event.preventDefault();
		try {
			setIsLoading(true);
			const response = await api.get(`/jobs/runSingleJob?id=${job.id}`);
			jobUpdated();
			toast.success('Det schemalagda jobbet körs.');

			//handleSetAlert({ message: 'Det schemalagda jobbet har uppdaterats.', type: 'success' }, 3000);
		} catch (error) {
			console.log(error.response);
			handleSetAlert({ message: error.response.data.message, type: 'danger' });
		} finally {
			setIsLoading(false);
		}
	};

	const deleteJobAndContent = async (event, job) => {
		setIsLoading(true);
		event.preventDefault();
		try {
			const response = await api.post(`/jobs/deleteJobAndContent/${job.id}`);
			//handleSetAlert({ ...response.data, timeout: 5000 });
			toast.success(response.data.message);
			window.setTimeout(() => {
				jobUpdated();
				setIsLoading(false);
			}, 5000);
		} catch (error) {
			handleSetAlert({ ...error.response.data, timeout: 5000 });
			setIsLoading(false);
		} finally {
		}
	};

	const showInProgressSpinner = (job) => {
		if (job.scraping_in_progress) {
			return (
				<div className="spinner-border spinner-border-sm text-primary ms-2" role="status">
					<span className="sr-only"></span>
				</div>
			);
		}
	};

	const handleShowCardDetails = (e) => {
		e.preventDefault();
		setShowCardDetails(!showCardDetails);
	};

	return (
		<div className="" key={`job-id-${job.id}`}>
			<div className="card mb-3">
				<div className="card-body">
					<h5 className="card-title h6 mb-0">
						{job.thread.title ? job.thread.title : '[Trådtiteln är inte hämtad ännu]'}
						{showInProgressSpinner(job)}
					</h5>
					<p className="text-muted mb-0">{job.thread.forum}</p>
					<p className="mb-0">
						<strong>Kördes senast: </strong>
						{job.last_run_at ? formatDate(job.last_run_at) : 'Jobbet har inte körts ännu'}
					</p>
					<div className={`card-details mt-2 mb-4 ${!showCardDetails ? 'd-none' : ''}`}>
						<div className="row">
							<div className="col-12">
								<p className="mb-0">
									<strong>URL: </strong>
									<a href={job.url} target="_blank">
										{job.url}
									</a>
								</p>
							</div>

							<div className="col-12 col-md-6">
								<p className="mb-0">
									<strong>Senast scannad sida: </strong>
									<a href={`${job.url}p${job.last_page_scraped}`} target="_blank">
										{job.last_page_scraped}
									</a>
								</p>
							</div>
							<div className="col-12 col-md-6 mb-2">
								<p className="mb-0">
									<strong>Totalt antal sidor: </strong>
									{job.thread ? job.thread.number_of_pages : '-'}
								</p>
							</div>
							<div className="col-12">{job.thread.short_summary && <p className="text-muted">{job.thread.short_summary}</p>}</div>
							<div className="col-12">
								<div className="card-actions">
									<a
										href="#"
										onClick={(e) => {
											runSingleJob(e, job);
										}}
										className={`btn btn-info mb-1 me-2 ${
											isLoading || job?.scraping_in_progress ? 'btn-outline-dark disabled' : ''
										}`}>
										Kör jobb nu
									</a>
									<a
										href="#"
										onClick={(e) => {
											updateJob(e, job, { is_active: job.is_active ? false : true });
										}}
										className={`btn ${job.is_active ? 'btn-primary' : 'btn-outline-primary'} me-2 mb-1 ${
											isLoading ? 'disabled' : ''
										}`}>
										{job.is_active ? 'Pausa' : 'Aktivera'}
									</a>
									<a
										href="#"
										onClick={(e) => {
											deleteJobAndContent(e, job);
										}}
										className={`btn btn-danger mb-1 ${isLoading ? 'disabled' : ''}`}>
										Ta bort jobb och innehåll
									</a>
								</div>
							</div>
						</div>
					</div>
					{alert && <Alert className="mt-3" message={alert.message} type={alert.type} timeout={alert.timeout} />}
				</div>
				<a href="#" className="toggle-job-card-details" onClick={(e) => handleShowCardDetails(e)}>
					{!showCardDetails ? 'Visa mer +' : 'Visa mindre -'}
				</a>
			</div>
		</div>
	);
};

JobCard.propTypes = {
	job: PropTypes.object.isRequired,
};
