import { useState } from 'react';
import { Alert } from './../Alert';
import { useAlert } from '../../hooks/useAlert';
import { useApi } from '../../utils/api';
import { toast } from 'react-toastify';

export const CreateJobInput = ({ getAllJobs }) => {
	const [newJob, setNewJob] = useState('');
	const { alert, handleSetAlert } = useAlert();
	const api = useApi();

	const handleSubmit = async (event) => {
		if (event) {
			event.preventDefault();
		}
		try {
			const response = await api.post(`/jobs/createJob/`, {
				url: newJob,
			});
			toast.success('Jobbet har skapats');
		} catch (error) {
			handleSetAlert({ ...error.response.data, timeout: 5000 });
		} finally {
			setNewJob('');
			getAllJobs();
		}
	};

	return (
		<form onSubmit={(e) => handleSubmit(e)}>
			{alert && (
				<div className="col-12">
					<Alert message={alert.message} type={alert.type} timeout={10000} />
				</div>
			)}
			<div className="row">
				<div className="col-12 col-md-9">
					<div className="input-container mt-2">
						<input
							type="url"
							className="form-control"
							placeholder="Ange URL för den tråd du vill skanna"
							onChange={(e) => setNewJob(e.target.value)}
							value={newJob}
						/>
					</div>
				</div>
				<div className="col-4 col-md-3">
					<div className="submit-container mt-2 mb-2">
						<a className={`btn btn-primary w-100`} onClick={handleSubmit}>
							Skapa
						</a>
					</div>
				</div>
			</div>
		</form>
	);
};
