export const ChatMessageSuggestions = ({ handleSelectSuggestion, suggestions = false }) => {
	if (!suggestions) {
		suggestions = ['Kan du sammanfatta tråden?', 'Vilka namn på människor förekommer i tråden?', 'Finns det några relevanta länkar i tråden?'];
	} else {
		/* Limit suggestions to 3 items */
		suggestions = suggestions.slice(0, 3);
	}

	const handleClick = (suggestion) => {
		handleSelectSuggestion(suggestion);
	};

	const showSuggestions = () => {
		return suggestions.map((suggestion, index) => {
			return (
				<a href="#" className="chat-suggestion" key={`suggestion-${index}`} onClick={() => handleClick(suggestion)}>
					{suggestion}
				</a>
			);
		});
	};
	return <div className="chat-message-suggestions">{showSuggestions()}</div>;
};

export default ChatMessageSuggestions;
