import React, { useEffect, useState } from 'react';
import { Jobs } from '../components/Jobs/Jobs';
import { useApp } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Logs } from '../components/Admin/Logs';
import { InvitationCodes } from '../components/Admin/InvitationCodes';

export const AdminPage = () => {
	const { user } = useApp();
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState('jobs');
	const availableTabs = [
		{ name: 'jobs', label: 'Schemalagda jobb' },
		//{ name: 'users', label: 'Användare' },
		{ name: 'invitation_codes', label: 'Inbjudningskoder' },
		{ name: 'logs', label: 'Loggar' },
	];

	const showNavTabs = () => {
		return availableTabs.map((tab, index) => {
			return (
				<li className="nav-item" key={`tab-${tab.name}`}>
					<a
						key={`nav-tab-${tab.name}`}
						className={`nav-link ${activeTab === tab.name ? 'active' : ''}`}
						href="#"
						onClick={() => setActiveTab(tab.name)}>
						{tab.label}
					</a>
				</li>
			);
		});
	};

	useEffect(() => {
		/* Redirect the user to the login page if they are not authenticated */
		if (user) {
			if (user.role !== 'admin') {
				toast.error('Du har inte behörighet att visa den här sidan.');
				navigate('/signin');
			}
		}
	}, [user, navigate]);

	return (
		<div>
			<div className="container mt-4">
				<div className="row">
					<div className="col-12 col-md-12 col-lg-8 offset-lg-2">
						<ul className="nav nav-tabs">{showNavTabs()}</ul>
						<div className="tab-content">
							<div className="tab-pane active">
								{activeTab === 'jobs' && <Jobs />}
								{activeTab === 'invitation_codes' && <InvitationCodes />}
								{activeTab === 'logs' && <Logs />}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
