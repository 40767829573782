import { useState } from 'react';
import axios from 'axios';
import { Alert } from '../Alert';
import { useAlert } from '../../hooks/useAlert';
import { useApp } from '../../context/AppContext';
import { toast } from 'react-toastify';

export const SignInForm = () => {
	const { alert, handleSetAlert } = useAlert();
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const { signIn } = useApp();

	const handleSubmit = async (event) => {
		if (isLoading) return;
		event.preventDefault();
		setIsLoading(true);
		const apiUrl = process.env.REACT_APP_API_URL;
		try {
			let data = {
				email: email,
				password: password,
			};
			const response = await axios.post(`${apiUrl}/auth/login`, data);
			const user = response?.data ? response.data : null;

			if (!user) {
				handleSetAlert({ message: 'Inloggningen misslyckades', type: 'danger', timeout: 5000 });
				return;
			}

			toast.info('Du är inloggad!');
			signIn(user);
		} catch (error) {
			if (error && error.response && error.response.data) {
				handleSetAlert({ ...error.response.data, timeout: 5000 });
			} else {
				handleSetAlert({ message: 'Något gick fel med inloggningen.', type: 'danger', timeout: 5000 });
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleSetEmail = (e) => {
		setEmail(e.target.value);
	};

	const handleSetPassword = (e) => {
		setPassword(e.target.value);
	};

	return (
		<div>
			<form className={`fb-form ${isLoading ? 'disabled' : ''}`} onSubmit={(e) => handleSubmit(e)}>
				<h1 className="mb-4">Logga in</h1>
				<div className="row">
					<div className="col-12 col-md-6 mb-2">
						<label htmlFor="email">Email</label>
						<input
							className="form-control"
							id="email"
							name="email"
							type="email"
							placeholder="Ange din e-postadress"
							defaultValue={email}
							onChange={(e) => handleSetEmail(e)}></input>
					</div>
					<div className="col-12 col-md-6 mb-2">
						<label htmlFor="password">Lösenord</label>
						<input
							className="form-control"
							id="password"
							name="password"
							type="password"
							placeholder="Ange ditt lösenord"
							defaultValue={password}
							onChange={(e) => handleSetPassword(e)}></input>
					</div>
					{alert && (
						<div className="col-12">
							<Alert className="mt-2 mb-2" message={alert.message} type={alert.type} timeout={alert.timeout} />
						</div>
					)}
					<div className="col-12 text-end mt-2">
						<button className="btn btn-primary" type="submit">
							Logga in
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

SignInForm.propTypes = {};
