import React from 'react';
import { SignupForm } from '../components/Forms/SignupForm';

export const SignupPage = () => {
	return (
		<div>
			<div className="container mt-5">
				<div className="row">
					<div className="col-12 col-md-12 col-lg-6 offset-lg-3">
						<SignupForm />
					</div>
				</div>
			</div>
		</div>
	);
};
